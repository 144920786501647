






import DocsWebhookMd from '@/docs/webhook.md';
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';

export default {
  components: {
    MarkdownItVue
  },
  data () {
    return {
      content: DocsWebhookMd
    }
  }
}
